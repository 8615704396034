<template>
  <div class="add-transfer-stock">
    <KTCodePreview v-bind:title="title">
      <template v-slot:preview>
        <b-row class="pl-0">
          <b-col cols="6">
            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Ngày yêu cầu nhập hàng:</label>
                <date-picker
                  placeholder="Chọn ngày"
                  class="mb-2 mt-2 form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.requestDate"
                  :disabled="!isStorePONew"
                ></date-picker>
              </b-form-group>
            </b-row>
            <b-row v-if="checkShowDeliveryDate()">
              <b-form-group class="col-6 required-control">
                <label>Ngày dự kiến hàng đến kho:</label>
                <date-picker
                  placeholder="Chọn ngày"
                  class="mb-2 mt-2 form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.deliveryDate"
                  :disabled="action !== 'approve'"
                ></date-picker>
              </b-form-group>
            </b-row>
            <b-row v-if="action === 'confirm'">
              <b-form-group class="col-6 required-control">
                <label>Xác nhận ngày nhận hàng:</label>
                <date-picker
                  placeholder="Chọn ngày nhận hàng"
                  class="mb-2 mt-2 form-control form-control-sm"
                  :config="dpConfigs.date"
                  v-model="mainModel.receivedDate"
                  :disabled="action !== 'confirm'"
                ></date-picker>
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-6 required-control">
                <label>Chọn kho:</label>
                <Autosuggest
                  class="border-radius-none"
                  :model="searchStore"
                  :suggestions="filteredOptionStore"
                  placeholder="cửa hàng"
                  :limit="200"
                  @select="onSelectedStore"
                  @change="onInputChangeStore"
                  suggestionName="suggestionName"
                  :disabled="isDisableStore || !isStorePONew"
                />
              </b-form-group>
            </b-row>
            <b-row>
              <b-form-group class="col-12">
                <template>
                  <span>Ghi chú:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="mainModel.description"
                  :placeholder="'Ghi chú'"
                  :rows="3"
                  :max-rows="3"
                ></b-form-textarea>
              </b-form-group>
            </b-row>
            <b-row v-if="mainModel.status === STORE_PO_STATUS.CANCELD">
              <b-form-group class="col-12">
                <template>
                  <span>Lý do huỷ phiếu:</span>
                </template>
                <b-form-textarea
                  size="sm"
                  v-model="mainModel.cancelReason"
                  :rows="3"
                  :max-rows="3"
                  disabled
                ></b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col
            cols="6"
            v-if="mainModel.id"
          >
            <b-row>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Mã phiếu:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.code }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Người tạo:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.createdByName }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Ngày tạo:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.createdAt }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Người cập nhật:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.updatedByName }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Ngày cập nhật:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.updatedAt }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="mainModel.status === STORE_PO_STATUS.CONFIRMED">
              <b-col>
                <b-form-group>
                  <template>
                    <span>Người xác nhận:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.confirmedByName }}</p>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group>
                  <template>
                    <span>Ngày xác nhận:</span>
                  </template>
                  <p class="mt-2">{{ mainModel.receivedDate }}</p>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-if="mainModel.purchasingDepartment?.storePoApprovalPolicy">
              <b-col cols="12">
                <b-form-group>
                  <label class="font-weight-bold">Chính sách duyệt phiếu nhập hàng - NH: {{
                    mainModel.purchasingDepartment.name
                    }}</label>
                  <b-form-textarea
                    size="lg"
                    rows="5"
                    v-model="mainModel.purchasingDepartment.storePoApprovalPolicy"
                    aria-describedby="input-fullName-live-feedback"
                    disabled
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr
          class="hr-text font-weight-600"
          data-content="Sản phẩm"
        />

        <b-row>
          <b-form-group class="col-md-12 pr-0">
            <label>Tìm sản phẩm:</label>
            <b-col class="row pr-0">
              <b-col
                md="6"
                class="p-0"
              >
                <Autosuggest
                  :model="searchProductFor"
                  :suggestions="optionProducts"
                  placeholder="tên, mã, mã vạch sản phẩm"
                  :limit="10"
                  @select="onSelectedProductFor"
                  @change="onInputChangeProductFor"
                  :disabled="false"
                  suggestionName="productName"
                />
              </b-col>
              <b-col
                md="2"
                class="p-0"
              >
                <b-form-select
                  class="
                    select-style
                    border-left-0
                    rounded-left-0 rounded-right-0
                  "
                  v-model="selectTypeSearchFor"
                  :options="listTypeSearch"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
              <b-col
                md="2"
                class="p-0"
              >
                <b-form-select
                  class="select-style border-left-0 rounded-left-0"
                  v-model="selectTypeOfProductFor"
                  :options="productTypes"
                  size="sm"
                  value-field="id"
                  text-field="name"
                  disabled-field="notEnabled"
                  @change="debounceInputFor"
                ></b-form-select>
              </b-col>
            </b-col>
          </b-form-group>
        </b-row>
        <b-row>
          <b-col md="12">
            <div>
              <b-table
                bordered
                hover
                class="col-md-12"
                :fields="productFields"
                :items="mainModel.storePurchaseOrderDetails"
                :per-page="10"
                :current-page="currentPageProductTable"
              >
                <template #cell(count)="row">
                  {{ row.item.count }}
                </template>
                <template
                  #cell(inStockQuantity)="row"
                  v-if="action !== 'create'"
                >
                  <span
                    v-b-tooltip.hover
                    title="Tồn thực tế"
                    class="text-primary pr-1 font-weight-bold"
                  >{{
                    convertPrice(row.item.inStockQuantity) }}</span> +
                  <span
                    v-b-tooltip.hover
                    title="Tồn đang chuyển kho"
                    class="text-warning pl-1 font-weight-bold"
                  >{{
                    convertPrice(row.item.transferQuantity) }}</span>
                </template>
                <template
                  #cell(sellingPower)="row"
                  v-if="action !== 'create'"
                >
                  <span class="font-weight-bold">Theo tuần:</span> {{ row.item.totalSalePowerWeek }}<br>
                  <span class="font-weight-bold">Theo tháng:</span> {{ row.item.totalSalePowerMonth }}
                </template>
                <template #cell(productName)="row">
                  <span
                    v-b-tooltip.hover.right="'Bar code'"
                    class="font-weight-600"
                  >{{ row.item.barCode }}</span><br />
                  <span v-b-tooltip.hover.right="'Product Id'">{{ row.item.productId }}</span><br />
                  <span v-b-tooltip.hover.right="'Product name'">{{
                    row.item.productName
                  }}</span>
                </template>
                <template #cell(sellingPrice)="row">
                  {{ convertPrice(row.item.sellingPrice) }}
                </template>
                <template v-slot:cell(quantity)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style text-right"
                    v-model="row.item.quantity"
                    v-mask="mask"
                    :disabled="disabledUpdateQuantity()"
                  ></b-input>
                </template>
                <template v-slot:cell(quantityApproved)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style text-right"
                    v-model="row.item.quantityApproved"
                    v-mask="mask"
                    :disabled="disabledAproveQuantity()"
                  ></b-input>
                </template>
                <template v-slot:cell(quantityConfirmed)="row">
                  <b-input
                    type="text"
                    size="sm"
                    class="input-style text-right"
                    v-model="row.item.quantityConfirmed"
                    v-mask="mask"
                    :disabled="disabledConfirmQuantity()"
                  ></b-input>
                </template>
                <template
                  v-slot:cell(actions)="row"
                  v-if="checkShowButtonDeleteProduct()"
                >
                  <i
                    class="flaticon2-rubbish-bin-delete-button text-danger"
                    role="button"
                    @click="
                    showDeleteAlert(
                      'Xoá sản phẩm',
                      `Bạn có chắc muốn xoá sản phẩm ${row.item.productName} `,
                      row.item,
                      confirmDeleteProduct,
                    )
                    "
                  ></i>
                </template>
              </b-table>
              <b-row>
                <b-col>
                  <p class="mt-3 text-dark font-weight-600">
                    Tổng số:
                    {{ mainModel.storePurchaseOrderDetails.length }}
                  </p>
                </b-col>
                <b-pagination
                  class="custom-pagination"
                  v-show="mainModel.storePurchaseOrderDetails.length > 10"
                  v-model="currentPageProductTable"
                  :total-rows="mainModel.storePurchaseOrderDetails.length"
                  :per-page="10"
                  align="right"
                >
                </b-pagination>
              </b-row>
            </div>
          </b-col>
          <b-col
            cols="6"
            md="4"
          ></b-col>
        </b-row>
      </template>
      <template v-slot:foot>
        <b-button
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="action && !isBusy"
          @click="onSubmitDebounce()"
        >
          {{ actionName }}
        </b-button>
        <b-button
          v-if="isBusy"
          variant="primary"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          disabled
        >
          <b-spinner
            small
            type="grow"
          ></b-spinner>
          {{ actionName }}...
        </b-button>
        <b-button
          variant="danger"
          size="sm"
          class="font-weight-bolder mr-2 btn-size"
          v-if="action === 'approve' && !isBusy"
          @click="showModalCancelStorePO(mainModel)"
        >
          Huỷ phiếu
        </b-button>
        <b-button
          variant="secondary"
          size="sm"
          class="btn-size font-weight-600"
          @click="goBack()"
        >Trở về</b-button>
        <template>
          <CancelStorePoModal
            :cancelAlert="cancelAlert"
            @refreshPage="refreshPage"
            :checkedItem="cancelItem"
            ref="cancel-store-po-modal"
          />
        </template>
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import { convertPrice } from '@/utils/common';
import localData from '@/utils/saveDataToLocal';
import timeUtils from '@/utils/date';
import { removeAccents } from '@/utils/common';
import Autosuggest from '@/view/base/auto-sugguest/AutoSuggest.vue';
import { TIME_TRIGGER } from '@/utils/constants';
import { makeToastFaile, unMaskPrice, currencyMask } from '@/utils/common';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import {
  formatDBDate,
  formatDate,
  CLIENT_DATE_TIME,
  YEAR_MONTH_DAY_FORMAT,
  DATE_FORMAT,
} from '@/utils/date';
import { makeToastSuccess } from '@/utils/common';
import { STATUS_CODE } from '@/utils/enum';
import { STORE_PO_STATUS } from '@/utils/enum';

import debounce from 'lodash/debounce';
import cloneDeep from 'lodash/cloneDeep';
import { getData } from '@/utils/saveDataToLocal';
import moment from 'moment';
import CancelStorePoModal from '@/view/components/store-purchase-order/cancel-store-po';

export default {
  data() {
    return {
      actionName: 'Lưu',
      cancelAlert: {
        actionName: 'Xác nhận',
        title: 'BẠN CÓ CHẮC MUỐN HUỶ PHIẾU!',
      },
      cancelItem: null,
      STORE_PO_STATUS,
      isStorePONew: true,
      action: null,
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      mask: currencyMask,
      currentPageProductTable: 1,
      productFields: [
        {
          key: 'count',
          label: 'STT',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'productName',
          label: 'Tên sản phẩm',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '16%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'inStockQuantity',
          label: 'Tồn',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'sellingPower',
          label: 'Sức bán',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '10%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'sellingPrice',
          label: 'Giá',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '5%',
          },
          tdClass: 'text-right',
        },
        {
          key: 'quantity',
          label: 'SL',
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '8%',
          },
          tdClass: 'text-left',
        },
      ],
      selectTypeOfProductFor: 0,
      title: 'Thêm phiếu yêu cầu nhập hàng',
      filteredProducts: [],
      actionField: {
        key: 'actions',
        label: '',
        tdClass: 'text-center',
        thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
      },
      quantityApproveField: {
        key: 'quantityApproved',
        label: 'SL duyệt',
        thStyle: {
          fontWeight: 600,
          color: '#181c32',
          width: '8%',
        },
        tdClass: 'text-left',
      },
      quantityConfirmeField: {
        key: 'quantityConfirmed',
        label: 'SL xác nhận',
        thStyle: {
          fontWeight: 600,
          color: '#181c32',
          width: '8%',
        },
        tdClass: 'text-left',
      },
      productTypes: [
        {
          id: 0,
          name: 'Tất cả sản phẩm',
        },
        {
          id: 1,
          name: 'Sản phẩm cha',
        },
        {
          id: 2,
          name: 'Sản phẩm con',
        },
        {
          id: 3,
          name: 'Sản phẩm độc lập',
        },
        {
          id: 4,
          name: 'Sản phẩm cha + độc lập',
        },
        {
          id: 5,
          name: 'Sản phẩm con + độc lập',
        },
      ],
      selectTypeSearchFor: 1,
      searchProductFor: '',
      totalPrice: 0,
      totalQuantity: 0,
      filteredOptionsProduct: [],
      selectTypeSearch: 1,
      mainModel: {
        id: null,
        storeId: null,
        requestDate: formatDate(new Date(), DATE_FORMAT),
        description: null,
        storePurchaseOrderDetails: [],
        receivedDate: null,
        purchasingDepartment: {},
      },
      dpConfigs: timeUtils.DP_CONFIG,
      searchStore: '',
      searchProduct: '',
      filteredOptionStore: [],
      isDisableStore: false,
      listTypeSearch: [
        {
          id: 1,
          name: 'Sản phẩm IMEI',
        },
        {
          id: 2,
          name: 'Sản phẩm',
        },
      ],
      currentStoreId: null,
      optionsStore: [],
      isBusy: false,
    };
  },
  components: {
    KTCodePreview,
    Autosuggest,
    CancelStorePoModal,
  },
  async created() {
    const { id, action } = this.$route.query;
    this.action = action;
    const promises = [this.fetchStoreByUser()];
    if (id) {
      this.mainModel.id = id;
      promises.push(this.getStorePOInfo());
    }

    if (this.action === 'create' || this.action === 'update') {
      const employeeInfo = getData('employee_info');
      this.currentStoreId = JSON.parse(employeeInfo)?.storeId;
      promises.push(this.initSuggestProductSearch());
    }
    await Promise.all(promises);
    this.convertActionNameByAction();
    this.handleProductFieldsByAction();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.title }]);
  },
  computed: {
    optionProducts() {
      return [...cloneDeep(this.filteredProducts)];
    },
  },
  methods: {
    formatDate,
    convertPrice,
    showDeleteAlert,
    refreshPage() {
      this.$router.replace({
        name: 'store-purchase-order',
        query: { status: 4 },
      });
    },
    showModalCancelStorePO(item) {
      this.cancelItem = item;
      this.$refs['cancel-store-po-modal'].showModal();
    },
    convertActionNameByAction() {
      const actionNameMap = {
        approve: 'Duyệt phiếu',
        confirm: 'Xác nhận',
        create: 'Lưu',
        update: 'Cập nhật',
      };
      this.actionName = actionNameMap[this.action] || 'Lưu';
    },
    checkShowDeliveryDate() {
      return this.action !== 'create' && this.action !== 'update';
    },
    handleProductFieldsByAction() {
      if (this.action === 'create' || this.action === 'update') {
        this.productFields.push(this.actionField);
      }

      switch (this.action) {
        case 'approve':
          this.productFields.push(this.quantityApproveField);
          break;
        case 'confirm':
        case 'complete':
          this.productFields.push(
            ...[this.quantityApproveField, this.quantityConfirmeField],
          );
          break;
        default:
          if (!this.action) {
            if (this.mainModel.status === STORE_PO_STATUS.APPROVED) {
              this.productFields.push(this.quantityApproveField);
            } else if (this.mainModel.status === STORE_PO_STATUS.CONFIRMED) {
              this.productFields.push(
                ...[this.quantityApproveField, this.quantityConfirmeField],
              );
            }
          }
          break;
      }
    },
    disabledConfirmQuantity() {
      return this.mainModel.status === STORE_PO_STATUS.CONFIRMED;
    },
    disabledAproveQuantity() {
      const allowedStatus = [
        STORE_PO_STATUS.REQUEST_APPROVAL,
        STORE_PO_STATUS.APPROVED,
      ];
      if (
        this.action !== 'approve' ||
        !allowedStatus.includes(this.mainModel.status)
      ) {
        return true;
      }
      return false;
    },
    disabledUpdateQuantity() {
      const allowedActions = ['create', 'update'];
      if (
        !allowedActions.includes(this.action) &&
        this.mainModel.status !== STORE_PO_STATUS.NEW
      ) {
        return true;
      }

      return false;
    },
    checkShowButtonDeleteProduct() {
      return this.action === 'create' || this.action === 'update';
    },
    goBack() {
      this.$router.go(-1);
    },
    async getStorePOInfo() {
      try {
        const { data } =
          (await ApiService.get(`store-purchase-order/${this.mainModel.id}`)) ||
          {};
        const storePOFromDb = data?.data;
        this.mainModel = {
          ...storePOFromDb,
          requestDate: formatDate(storePOFromDb.requestDate, DATE_FORMAT),
          deliveryDate: formatDate(storePOFromDb.deliveryDate, DATE_FORMAT),
          receivedDate: formatDate(storePOFromDb.receivedDate, DATE_FORMAT),
          updatedAt: formatDate(storePOFromDb.updatedAt, DATE_FORMAT),
          createdAt: formatDate(storePOFromDb.createdAt, DATE_FORMAT),
          storePurchaseOrderDetails: this.mappingStorePODetailsRes(
            storePOFromDb,
            storePOFromDb.storePurchaseOrderDetails,
          ),
        };
        this.searchStore = storePOFromDb.storeName;
        this.isStorePONew = this.mainModel.status === STORE_PO_STATUS.NEW;
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    mappingStorePODetailsRes(storePO, storePurchaseOrderDetails = []) {
      const isApproved = storePO.status === STORE_PO_STATUS.APPROVED;
      const isRequestApproval =
        storePO.status === STORE_PO_STATUS.REQUEST_APPROVAL;
      return storePurchaseOrderDetails.map((detail, index) => {
        const { quantity, quantityApproved, quantityConfirmed } = detail;
        return {
          ...detail,
          count: ++index,
          quantityApproved: isRequestApproval ? quantity : quantityApproved,
          quantityConfirmed: isApproved ? quantityApproved : quantityConfirmed,
        };
      });
    },
    confirmDeleteProduct(deleteItem) {
      this.mainModel.storePurchaseOrderDetails =
        this.mainModel.storePurchaseOrderDetails.filter(
          ({ productId }) => productId !== deleteItem.productId,
        );
    },
    createStorePO(body) {
      return ApiService.post('store-purchase-order', body);
    },
    updateStorePO(body) {
      return ApiService.patch(
        `store-purchase-order/${this.mainModel.id}`,
        body,
      );
    },
    approveStorePO(body) {
      return ApiService.put(
        `store-purchase-order/${this.mainModel.id}/approve`,
        body,
      );
    },
    requestApprovalPO() {
      return ApiService.put(`store-purchase-order/${this.mainModel.id}`);
    },
    confirmStorePO(body) {
      if (!body.receivedDate) {
        throw new Error('Ngày xác nhận không được trống!');
      }
      return ApiService.put(
        `store-purchase-order/${this.mainModel.id}/confirm`,
        body,
      );
    },
    completeStorePO(body) {
      return ApiService.put(
        `store-purchase-order/${this.mainModel.id}/complete`,
        body,
      );
    },
    dispatchStorePORequest(action, body) {
      switch (action) {
        case 'create':
          return this.createStorePO(body);
        case 'update':
          return this.updateStorePO(body);
        case 'approve':
          return this.approveStorePO(body);
        case 'confirm':
          return this.confirmStorePO(body);
        case 'complete':
          return this.completeStorePO(body);
        default:
          throw new Error(`Action: '${action}' không hợp lệ!`);
      }
    },
    onSubmitDebounce: debounce(function () {
      this.onSubmit();
    }, TIME_TRIGGER),
    async onSubmit() {
      if (this.isBusy) return;
      this.isBusy = true;
      const body = {
        ...this.mainModel,
        requestDate: formatDBDate(this.mainModel.requestDate),
        storePurchaseOrderDetails: this.mapStorePODetailsModel(
          this.mainModel.storePurchaseOrderDetails,
        ),
        receivedDate:
          this.action === 'confirm'
            ? formatDBDate(this.mainModel.receivedDate)
            : undefined,
        deliveryDate:
          this.action === 'approve'
            ? formatDBDate(this.mainModel.deliveryDate)
            : undefined,
      };
      try {
        const result = await this.dispatchStorePORequest(this.action, body);
        if (result.data?.status === STATUS_CODE.SUCCESS) {
          makeToastSuccess(result.data.message);
          this.goBack();
        }
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.isBusy = false;
      }
    },
    mapStorePODetailsModel(storePurchaseOrderDetails) {
      return storePurchaseOrderDetails.map((storePODetail) => {
        const details = {
          productId: storePODetail.productId,
          quantity: Number(storePODetail.quantity),
          quantityApproved: Number(storePODetail.quantityApproved),
          quantityConfirmed: Number(storePODetail.quantityConfirmed),
        };
        if (storePODetail.id) {
          details.id = storePODetail.id;
        }
        return details;
      });
    },
    debounceInputFor: debounce(function () {
      if (!this.isSearching) {
        let textSearch = this.searchProductFor;
        this.fetchProduct(textSearch);
      }
    }, TIME_TRIGGER),
    onInputChangeProductFor(text) {
      this.searchProductFor = text;
      this.debounceInputFor();
    },
    async fetchProduct(textSearch) {
      try {
        this.filteredProducts = this.filterProduct(textSearch);
        const searchProduct = textSearch ? textSearch.trim() : null;
        if (!this.filteredProducts.length) {
          this.isSearching = true;
          const paramsSearch = {
            productName: searchProduct,
            storeId: this.currentStoreId,
            fromDate: this.getStartOfMonth(),
          };
          const data = await this.searchProductInOrderCannotServe(paramsSearch);
          this.filteredProducts = data?.result || [];
          //if product in order cannot serve not exist => call api search product
          if (!this.filteredProducts.length) {
            const params = {
              searchProduct: searchProduct,
              typeSearch: this.selectTypeSearchFor,
              typeOfProduct: this.selectTypeOfProductFor,
            };
            this.filteredProducts = await this.searchProductByType(params);
          }
        }
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      } finally {
        this.isSearching = false;
      }
    },
    filterProduct(searchText) {
      return this.filteredProducts.filter((item) => {
        return (
          item.productName.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1 ||
          removeAccents(item.productName).indexOf(removeAccents(searchText)) >
            -1
        );
      });
    },
    async searchProductByType(params) {
      const {
        data: { data },
      } = await ApiService.query(`productSearch/search-by-type`, {
        params,
      });
      return data;
    },
    async searchProductInOrderCannotServe(params) {
      const {
        data: { data },
      } = await ApiService.query('store-purchase-order/search-product', {
        params,
      });
      return data;
    },
    async initSuggestProductSearch() {
      const params = {
        storeId: this.currentStoreId,
        limit: 30,
        fromDate: this.getStartOfMonth(),
      };
      const response = await this.searchProductInOrderCannotServe(params);
      this.filteredProducts = response.result;
    },
    getStartOfMonth() {
      return moment(new Date()).startOf('month').format(YEAR_MONTH_DAY_FORMAT);
    },
    onSelectedProductFor(option) {
      this.searchProductFor = '';
      const index = this.mainModel.storePurchaseOrderDetails.findIndex(
        (product) => {
          return product.productId == option.item.productId;
        },
      );
      if (index > -1) {
        return makeToastFaile(`${option.item.productName} đã tồn tại`);
      }
      let count = this.mainModel.storePurchaseOrderDetails.length + 1;
      this.mainModel.storePurchaseOrderDetails.unshift({
        productId: option.item.productId,
        quantity: unMaskPrice(option.item.quantity) || 0,
        productName: option.item.productName,
        productCode: option.item.productCode,
        sellingPrice: option.item.sellingPrice,
        count: count,
      });
    },
    onSelectedStore({ item }) {
      this.mainModel.storeId = item.id;
      this.searchStore = item.name;
      this.mainModel.storePurchaseOrderDetails = [];
    },
    onInputChangeStore(text) {
      if (!text) {
        text = '';
        this.mainModel.storeId = null;
      }
      this.searchStore = text;
      const filteredData = this.optionsStore
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            removeAccents(item.name).indexOf(removeAccents(text)) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptionStore = [...filteredData];
    },
    async fetchStoreByUser() {
      try {
        const { data } = (await ApiService.get(`stores/get-by-user`)) || {};
        const stores = data.data?.stores;
        if (stores.length === 1) {
          this.mainModel.storeId = stores[0].id;
          this.searchStore = stores[0].name;
          this.isDisableStore = true;
        }
        this.optionsStore = stores.map((element) => {
          return {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
        });
        this.filteredOptionStore = [...this.optionsStore];
      } catch (error) {
        const message =
          error.response?.data?.message ?? error.message ?? 'Network error';
        makeToastFaile(message);
      }
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
  },
};
</script>
<style scoped>
.font-weight-600 {
  font-weight: 600;
}

.btn-size {
  width: 90px;
}
</style>
